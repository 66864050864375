/* eslint-disable @next/next/no-img-element */
import { AssetPaths } from 'common/constants/assets';
import * as React from 'react';

export default function FlagChinaPlus(props: React.ComponentProps<'img'>) {
  return (
    <img
      src={AssetPaths.RegionChinaPlus}
      alt="China, Hong Kong, Macau"
      {...props}
    />
  );
}
