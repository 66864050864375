/* eslint-disable @next/next/no-img-element */
import { AssetPaths } from 'common/constants/assets';
import * as React from 'react';

export default function FlagSEATrio(props: React.ComponentProps<'img'>) {
  return (
    <img
      src={AssetPaths.RegionSEATrio}
      alt="Thailand, Malaysia, Singapore"
      {...props}
    />
  );
}
