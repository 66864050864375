import { Loader, Button as RawButton } from '@mantine/core';
import { WhatsappLogo } from '@phosphor-icons/react';
import { useAuth } from 'common/auth';
import exportEnv from 'common/config';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import colors from 'common/styles/colors';
import { generateUniqueId } from 'common/utils/random';
import Separator from 'components/common/separator';
import Button from 'components/elements/button';
import BottomSheet, {
  BottomSheetRemote,
} from 'components/widgets/bottom-sheet';
import { Crisp } from 'crisp-sdk-web';
import useCombinedRefs from 'hooks/use-combined-refs';
import useDetectDevice from 'hooks/use-detect-device';
import { useExternalSiteNavigation } from 'hooks/use-kurosim-navigation/navigator';
import {
  PopStateListenerChain,
  useRegisterKurosimPopStateListener,
} from 'hooks/use-kurosim-navigation/popstate';
import useLanguage, { getLanguageStorage } from 'hooks/use-language';
import { useWhatsappRedirection } from 'hooks/use-whatsapp-redirection';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CrispContextType {
  unreadCount: number;
  show(): void;
  hide(): void;
  isVisible(): boolean;
  ready: boolean;
}
export const CrispContext = React.createContext<CrispContextType>({
  unreadCount: 0,
  show() {},
  hide() {},
  isVisible() {
    return false;
  },
  ready: false,
});

let CRISP_IS_READY = false;

export function CrispProvider(props: React.PropsWithChildren) {
  const { t } = useTranslation();
  const query = useAuth();
  const [unreadCount, setUnreadCount] = React.useState(0);
  const [sessionLoaded, setSessionLoaded] = React.useState(false);
  const { isKurosimApp } = useDetectDevice();
  const { language } = useLanguage();

  const previousTitle = React.useRef('Kurosim');

  const send = useNativeBridge({});

  /*
  =================================
  INITIALIZE ANONYMOUS ID
  =================================
  */

  const [anonymousId, setAnonymousId] = React.useState<string>();
  React.useEffect(() => {
    // This value should persist while browser is open
    // EDIT: 17/10/2024. Crisp creates a new cookie for each session which would cause cookie build-up. We'll store the anonymous ID in localStorage instead, at the cost of user privacy.
    const existentAnonymousId = localStorage.getItem(
      LocalStorageKeys.CrispAnonymousSessionId,
    );
    if (existentAnonymousId) {
      setAnonymousId(existentAnonymousId);
    } else {
      const newAnonymousId = generateUniqueId();
      // Commit anonymous ID to local storage if it doesn't exist.
      setAnonymousId(newAnonymousId);
      localStorage.setItem(
        LocalStorageKeys.CrispAnonymousSessionId,
        newAnonymousId,
      );
    }
  }, [anonymousId, setAnonymousId]);

  const me = query?.data?.data;
  const crispTokenId = me?.id ?? anonymousId;
  const lastUsedTokenId = React.useRef<string | null>(null);
  const nickname = me
    ? [me.firstName, me.lastName].filter(Boolean).join(' ')
    : `Guest ${anonymousId}`;

  /*
  =================================
  CRISP CONFIGURATION
  =================================
  */
  React.useEffect(() => {
    Crisp.configure(exportEnv.crispWebsiteId, {
      autoload: false,
      lockFullview: true,
      sessionMerge: false,
    });
    Crisp.setHideOnMobile(false);
    Crisp.setVacationMode(false);

    Crisp.chat.onChatClosed(() => {
      Crisp.chat.hide();
      document.title = previousTitle.current;
      // Reset register
      previousTitle.current = 'Kurosim';
    });
    Crisp.message.onMessageReceived(async (data) => {
      setUnreadCount(Crisp.chat.unreadCount());
    });

    const onReady = () => {
      setUnreadCount(Crisp.chat.unreadCount());
      setSessionLoaded(true);
      Crisp.chat.hide();
      console.log('[Crisp] Session successfully loaded.');
    };
    Crisp.session.onLoaded(onReady);

    // https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/dollar-crisp/#use-crisp-before-it-is-ready
    (window as any).CRISP_READY_TRIGGER = () => {
      console.log('[Crisp] Crisp functionality has been loaded.');
      CRISP_IS_READY = true;
    };
    (window as any).CRISP_RUNTIME_CONFIG = {
      locale: getLanguageStorage(),
    };
    return () => {
      if ('$crisp' in window) {
        window.$crisp.push(['off', 'message:received']);
        window.$crisp.push(['off', 'session:loaded']);
      }
    };
  }, []);

  /*
  =================================
  CRISP INITIALIZATION
  =================================
  */
  React.useEffect(() => {
    // wait until crisp token id is loaded
    if (lastUsedTokenId.current === crispTokenId || !crispTokenId) return;

    Crisp.setTokenId(crispTokenId);
    lastUsedTokenId.current = crispTokenId;
    if (!CRISP_IS_READY) {
      Crisp.load();
      console.log(
        `[Crisp] Initializing Crisp with ${window.CRISP_TOKEN_ID}...`,
      );
    } else {
      try {
        // Crisp.session.reset();
        window.$crisp.push(['do', 'session:reset']);
        console.log(
          `[Crisp] Detected change in login state. Resetting current session with ${window.CRISP_TOKEN_ID}...`,
        );
        setSessionLoaded(false);
      } catch (e) {
        console.error(e);
      }
    }
    setUnreadCount(0);
  }, [crispTokenId]);

  /*
  =================================
  IDENTITY MANAGEMENT
  =================================
  */
  React.useEffect(() => {
    if (query.isFetching || !sessionLoaded || !CRISP_IS_READY || !anonymousId)
      return;
    console.log(
      `[Crisp] Setting user identity as ${me?.email ?? '<No Email>'} and ${nickname}`,
    );
    if (me?.email) {
      // https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/session-continuity/
      Crisp.user.setEmail(me.email);
    }
    Crisp.user.setNickname(nickname);
  }, [anonymousId, me?.email, nickname, query.isFetching, sessionLoaded]);

  React.useEffect(() => {
    const runtimeLocale = (window as any).CRISP_RUNTIME_CONFIG?.locale;
    if (runtimeLocale !== language) {
      console.log(`[Crisp] Setting language as ${language}`);
      (window as any).CRISP_RUNTIME_CONFIG = {
        locale: language,
      };
      window.$crisp.push(['do', 'session:reset']);
    }
  }, [language]);

  /*
  =================================
  LIVE CHAT ACTIONS
  =================================
  */

  const show = React.useCallback(() => {
    if (isKurosimApp && crispTokenId) {
      send?.({
        data: {
          email: me?.email,
          nickname,
          crispTokenId,
        },
        type: BridgeMessageType.LiveChat,
      });
    } else {
      // Very, very dubious hack especially since we're using next/head to set the title. Remove this if it causes weird behavior.
      Crisp.chat.show();
      previousTitle.current = document.title;
      document.title = `${t('title:live-chat')} | Kurosim`;
      Crisp.chat.open();
      window.history.pushState(
        {
          ...window.history.state,
          key: Math.random().toString(16).substring(2),
          crisp: true,
        },
        '',
        window.location.pathname,
      );
    }
  }, [crispTokenId, isKurosimApp, me?.email, nickname, send, t]);

  const hide = React.useCallback(() => {
    Crisp.chat.hide();
    document.title = previousTitle.current;
  }, []);

  const isVisible = React.useCallback(() => Crisp.chat.isVisible(), []);

  return (
    <CrispContext.Provider
      value={{
        show,
        hide,
        isVisible,
        ready: sessionLoaded,
        unreadCount,
      }}
    >
      {props?.children}
    </CrispContext.Provider>
  );
}

export const LiveChatBottomSheet = React.forwardRef<BottomSheetRemote, object>(
  function LiveChatBottomSheet(props, outerRef) {
    const { t } = useTranslation();
    const innerRef = React.useRef<BottomSheetRemote | null>();
    const ref = useCombinedRefs(innerRef, outerRef);
    const whatsapp = useWhatsappRedirection();
    const navigateExternal = useExternalSiteNavigation();

    const { language } = useLanguage();

    const {
      hide: hideLiveChat,
      show: showLiveChat,
      ready,
    } = React.useContext(CrispContext);

    useRegisterKurosimPopStateListener(
      'live-chat',
      (event) => {
        if (event.state?.crisp) {
          showLiveChat();
          return PopStateListenerChain.NoDefault;
        }
        if (Crisp.chat.isVisible()) {
          hideLiveChat();
          return PopStateListenerChain.Continue;
        }
        return PopStateListenerChain.Continue;
      },
      10,
    );

    const { isKurosimApp } = useDetectDevice();

    const leftSection = isKurosimApp
      ? undefined
      : !ready && <Loader size={20} color={colors.foregroundTertiary} />;

    const disabled = isKurosimApp ? undefined : !ready;

    const label = isKurosimApp
      ? t('profile:live_chat')
      : !ready
        ? t('profile:preparing_live_chat')
        : t('profile:live_chat');

    return (
      <BottomSheet
        defaultSnap={290}
        snapPoints={({ maxHeight }) => [290, maxHeight * 0.5, maxHeight]}
        ref={ref as any}
        title={t('profile:contact_us')}
      >
        <div>
          <Button
            onClick={() => {
              showLiveChat();
              innerRef.current?.close();
            }}
            fullWidth
            leftSection={leftSection}
            disabled={disabled}
          >
            {label}
          </Button>
          <Separator gap={16} />
          <RawButton
            fullWidth
            onClick={whatsapp}
            color="#25d366"
            leftSection={<WhatsappLogo size={20} />}
            h={48}
            pt={12}
            pb={12}
          >
            {t('profile:whatsapp')}
          </RawButton>
          <Separator gap={16} />
          <Button
            variant={{ variant: 'secondary' }}
            fullWidth
            onClick={() =>
              navigateExternal(`https://help.kurosim.com/${language}`)
            }
          >
            FAQ
          </Button>
        </div>
      </BottomSheet>
    );
  },
);
